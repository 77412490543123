
import { FEATURES } from './features.data';
import './features.styles.scss';
type FeatureProps = {
  icon : any,
  title: string,
  description: string
}

const Feature = ({icon, title, description} : FeatureProps) => {
  return (
    <div className='feature'>
      <div className='feature__icon'>
        {icon}
      </div>
      <div className='feature__title'>
        <h4>{title}</h4>
      </div>
      <div className='feature__description'>
        <span>{description}</span>
      </div>
    </div>
  )
}

const Features = () => {
  return (
    <div className='features'>
      {FEATURES.map((feature, index) => 
        <Feature key={index} {...feature} />
      )}
    </div>)
}

export default Features;