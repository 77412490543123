import logo from '../../assets/images/zuglabs-logo.svg';
import './header.styles.scss';

const Header = () => {
  return (
    <div className='header'>
      <div className='header__logo'>
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <div className='header__menu'></div>
      <div className='header__wallet'></div>
    </div>)
}

export default Header;