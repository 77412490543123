import { Outlet } from "react-router-dom";
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";

import './navigation.styles.scss';

const Navigation = () => {
  return (
    <div className='navigation'>
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

export default Navigation;