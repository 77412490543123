//import NewsletterSubscribe from "../newsletter/newsletter-subscribe.component";
import ContactUs from '../contact-us/contact-us.component';
import './footer.styles.scss';

const Footer = () => {
  return (
    <div className='footer'>
      <ContactUs />
      {/* <NewsletterSubscribe /> */}
    </div>
  )
}

export default Footer;