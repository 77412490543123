import Clients from "../../components/clients/clients.component";
import Features from "../../components/features/features.component";
import SectionTitle from "../../components/section-title/section-title.component";
import { LANDING_TEXT } from "./landing-page.data";
import "./landing-page.styles.scss";

type TextSectionProps = {
  heading? : string;
  body?: string;
}

const TextSection = ({heading, body} : TextSectionProps) => {
  return (
    <div className='text-section'>
      {heading && <div className='text-section__heading'>
        <h1>{heading}</h1>
      </div>}
      {body && <div className='text-section__body'>
        <h3>{body}</h3>
      </div>}
    </div>
  )
}

const LandingPage = () => {
  return (
    <div className="landing-page">
      <SectionTitle />
      {/* <div className="landing-page__title-section">
        <div className="landing-page__title-section--text">
          <p>{LANDING_TEXT.title}</p>
        </div>
        <div className="landing-page__title-section--image"></div>
      </div> */}
       <Features />
      <TextSection heading={LANDING_TEXT.heading1} body={LANDING_TEXT.body1} />
     
      <Clients />
    </div>
  );
};

export default LandingPage;
