import DPC_LOGO from '../../assets/images/DPC-logo.svg' // DPC-logo.svg'
import HONDA_LOGO from '../../assets/images/honda-logo.png';
import THREE13_LOGO from '../../assets/images/313-Logo.png';

export const CLIENTS = [
  {
    logo: DPC_LOGO,
    name: 'DPC',
    backgroundColor: 'purple',
    shortDescription: "BUY, SELL, TRADE, and HODL your NFTs on the most dynamic NFT ecosystem for sports enthusiasts and NFT collectors!",
    url: "https://www.dpc-nft.com/"
    
  },
  {
    logo: THREE13_LOGO,
    backgroundColor: 'orange',
    name: 'The 313 Project',
    shortDescription: "Mint your Socialites to build the city you love. Buy and Sell your NFTs to socialize and connect with your city",
    url: "http://313project.io/"
  },
  {
    logo: HONDA_LOGO,
    name: 'Honda',
    backgroundColor: 'white',
    shortDescription: "Construction of solutions in various projects such as payment methods on blockchain, development, and training of machine learning models",
    url: "https://xcelerator.hondainnovations.com/"
  }
]