import { Route, Routes } from "react-router-dom";

import Navigation from "./routes/navigation/navigation.component";
import LandingPage from "./routes/landing-page/landing-page.component";

import "./App.scss";

function App() {
  return (
    <div className="app-container">
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<LandingPage />} />
        </Route>
      </Routes>
      <div className="light x1"></div>
      <div className="light x2"></div>
      <div className="light x3"></div>
      <div className="light x4"></div>
      <div className="light x5"></div>
      <div className="light x6"></div>
      <div className="light x7"></div>
      <div className="light x8"></div>
      <div className="light x9"></div>
    </div>
  );
}

export default App;
