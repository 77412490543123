import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "@mui/material";

import "./contact-us.styles.scss";


const ContactUs = () => {
  // eslint-disable-next-line no-unused-vars
  const [isCopied, setIsCopied] = useState(false);
  return (
    <div className="contact-us">
      <h3>Contact Us</h3>
      <CopyToClipboard text="info@zuglabs.com">
        <Tooltip placement="top" title={isCopied ? "Email Address Copied" : "Click to copy Email Address"}>
          <h4>info@zuglabs.com</h4>
        </Tooltip>
      </CopyToClipboard>
    </div>
  );
};

export default ContactUs;
