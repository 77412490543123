import "./clients.styles.scss";
import { CLIENTS } from "./clients.data";

type ClientProps = {
  name: string;
  logo: any;
  backgroundColor: string,
  shortDescription: string;
  url: string;
};

const Client = ({ name, shortDescription, backgroundColor, logo, url }: ClientProps) => {
  console.log(logo);

  const goToPage = () => {
    window.open(url,"_blank","noopener,noreferrer");
  }
  
  return (
    <div className="client" onClick={goToPage}>
      <div className="client__logo" style={{ backgroundImage: `url(${logo})`, backgroundColor: backgroundColor}}>
        {/* <img src={logo} alt={name} /> */}
      </div>
      <div className="client__name">
        <h3>{name}</h3>
      </div>
      <div className="client__description">
        <span>{shortDescription}</span>
      </div>
    </div>
  );
};

const Clients = () => {
  return (
    <div className="clients">
      <div className="clients__title">
        <h1>Our Clients</h1>
      </div>
      <div className="clients__body">
        {CLIENTS.map((client, index) => (
          <Client key={index} {...client} />
        ))}
      </div>
    </div>
  );
};

export default Clients;
