import "./section-title.styles.scss";
import { LANDING_TEXT } from "../../routes/landing-page/landing-page.data";
const SectionTitle = () => {
  return (
    <div className="section-title">
      <div className='section-title__text'>
        <h1>{LANDING_TEXT.title}</h1>
      </div>
    </div>
  );
};

export default SectionTitle;
