import SchemaIcon from '@mui/icons-material/Schema';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

export const FEATURES = [
  {
    icon : <PrecisionManufacturingIcon fontSize='inherit'/>,
    title : 'Machine Learning',
    description: "The ZugLabs team of seasoned data scientists, machine learning engineers and researchers, with  vast expertise in unsupervised learning, supervised learning, self-supervised  and multi-modal learning, will work together with your staff to provide state-of-the-art solutions. We develop custom models based on the tools and technologies that  better fit our client's needs. (This text was generated used our own generative models, by the way)."
  },
  {
    icon : <SchemaIcon fontSize='inherit'/>,
    title : 'Blockchain',
    description: 'We provide reliable and advanced decentralized solutions for our clients and partners. We help companies and projects like yours to secure a position in this fast-evolving area, that is sustainable and adaptable so you can grasp the opportunities and manage the uncertainties that the future unfolds.'
  }
]